import React from "react"
export default function Footer() {
  return (
    <section className="section footer has-background-secondary">
      <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
        <div className="content">
          <p className="has-text-centered has-text-white"><a className="fb transition" href="https://www.facebook.com/anne.pitman.16"><svg xmlns="http://www.w3.org/2000/svg" width="15.469" height="30" viewBox="0 0 15.469 30"><path d="M4.494,30V16.582H0V11.25H4.494v-4.2C4.494,2.484,7.283,0,11.355,0a37.73,37.73,0,0,1,4.113.211V4.98H12.645C10.43,4.98,10,6.035,10,7.576V11.25h5l-.686,5.332H10V30"/></svg></a></p>
          <p className="has-text-centered has-text-white">All content © Anne Pitman unless otherwise mentioned.</p>
        </div>
      </div>
    </section>
  )
}